import Bugsnag from '@bugsnag/js'

// See https://github.com/bugsnag/bugsnag-js/blob/next/examples/js/nextjs/lib/bugsnag.js
export function start() {
  // if (process.env.NODE_ENV === 'development') {
  //   console.log('🐛 Bugsnag is disabled in development')
  //   return
  // }

  const { bugsnagApiKey, nextIsServer } = loadEnvs()

  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE
  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    if (nextIsServer === 'true') {
      Bugsnag.start({
        apiKey: bugsnagApiKey,
        appVersion: process.env.NEXT_BUILD_ID,
      })
    } else {
      // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
      Bugsnag.start({
        apiKey: bugsnagApiKey,
        appVersion: process.env.NEXT_BUILD_ID,
        plugins: [],
      })
    }
  }
}

const loadEnvs = () => {
  const bugsnagApiKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY

  if (bugsnagApiKey === undefined) {
    throw new Error('The NEXT_PUBLIC_BUGSNAG_API_KEY env variable is not defined')
  }

  const nextIsServer = process.env.NEXT_IS_SERVER

  if (nextIsServer === undefined) {
    throw new Error('The NEXT_IS_SERVER env variable is not defined')
  }

  return { bugsnagApiKey, nextIsServer }
}

